<template>
  <sheet dense>
    <div class="text-caption">{{ $t(label) }}</div>
    <ValidationProvider
      ref="fieldName"
      :name="$t('category')"
      :rules="required ? 'required' : ''"
      slim
      vid="category"
    >
      <v-treeview
        slot-scope="{ errors, valid }"
        :active="selectedCategoryId"
        :error-messages="errors"
        :items="items"
        :multiple-active="multiple"
        :open="open"
        :open-all="openAll"
        :success="valid"
        :value="selectedCategoryId"
        activatable
        dense
        open-on-click
        rounded
        @update:active="onSelectItem"
      >
        <template #prepend="{ item, open }">
          <component
            :is="
              item.children.length
                ? open
                  ? iconFolderOpen
                  : iconFolder
                : iconCategory
            "
            size="1.2em"
          />
        </template>
        <template #label="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
            </v-list-item-content>
            <v-list-item-action-text v-text="item.nestCode" />
          </v-list-item>
        </template>
      </v-treeview>
    </ValidationProvider>
  </sheet>
</template>
<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import { findDeep, mapValuesDeep } from "deepdash-es/standalone";
import type { CategoryData } from "@planetadeleste/vue-mc-shopaholic";
import {
  castArray,
  get,
  isArray,
  isEmpty,
  isNumber,
  isObjectLike,
  join,
  map,
  set,
} from "lodash";

interface ExtendedCategoryData extends CategoryData {
  nestCode: string;
}

@Component
export default class FormCategories extends Vue {
  @VModel({ type: [Number, Array], default: null }) categoryId!:
    | number
    | number[];
  @Prop(Boolean) readonly openAll!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly autoAssign!: boolean;
  @Prop({ type: String, default: "category" }) readonly label!: string;
  @Prop({ type: Boolean, default: true }) readonly required!: boolean;

  openCategoryId!: number | number[];

  get categories() {
    return AppModule.categories;
  }

  get items() {
    const arCategoryList = this.categories.getModelList();
    return mapValuesDeep(
      arCategoryList,
      (
        obCategoryItem: ExtendedCategoryData,
        sKey: string | number,
        obParent: ExtendedCategoryData
      ) => {
        const arCode = [];
        if (obParent && !isArray(obParent)) {
          arCode.push(obParent.nestCode);
        }

        arCode.push(obCategoryItem.code);
        set(obCategoryItem, "nestCode", join(arCode, "."));

        return obCategoryItem;
      },
      {
        childrenPath: ["children"],
      }
    );
  }

  get selectedCategoryId() {
    if (isNumber(this.categoryId)) {
      return [this.categoryId];
    } else if (isArray(this.categoryId)) {
      return map(this.categoryId, (item) => {
        return isNumber(item) ? item : get(item, "id");
      });
    }

    return [];
  }

  get iconFolder() {
    return "icon-folder-outline";
  }

  get iconFolderOpen() {
    return "icon-folder-open-outline";
  }

  get iconCategory() {
    return "icon-document-outline";
  }

  get open() {
    const arReturnListID: number[] = [];
    if (!this.openCategoryId) {
      return arReturnListID;
    }

    const obDeepEntry = findDeep(
      this.categories.getModelList(),
      (obCategory) => {
        return obCategory && obCategory.id == this.openCategoryId;
      },
      { childrenPath: ["children"] }
    );

    if (obDeepEntry && obDeepEntry.parent) {
      const sId: number | undefined = get(obDeepEntry.parent, "id");

      if (isObjectLike(obDeepEntry.parent) && sId) {
        arReturnListID.push(sId);
      } else if (isArray(obDeepEntry.parent)) {
        const arParentListID: number[] = map(
          obDeepEntry.parent,
          (obCat) => obCat.id
        );
        arReturnListID.concat(arParentListID);
      }
    }

    if (isNumber(this.openCategoryId)) {
      arReturnListID.push(this.openCategoryId);
    }

    return arReturnListID;
  }

  mounted() {
    this.$nextTick(() => {
      if (this.autoAssign && this.categories.length && !this.categoryId) {
        const obCategory = this.categories.first();
        if (obCategory) {
          this.onSelectItem(castArray(obCategory.id));
        }
      }

      this.openCategoryId = this.categoryId;
    });
  }

  onSelectItem(val: any) {
    if (get(val, "root")) {
      return;
    }

    const value = this.multiple ? val : isEmpty(val) ? null : val[0];
    if (value !== this.categoryId) {
      this.$emit("input", value);
    }
  }
}
</script>
