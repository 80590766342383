<template>
  <v-row>
    <v-col cols="12">
      <v-switch :label="$t('active')" v-model="obModel.active"></v-switch>
    </v-col>

    <v-col cols="6" lg="8">
      <v-row>
        <v-col cols="12" lg="6" xl="4">
          <form-field-text v-model="obModel.name" required />
        </v-col>

        <v-col cols="12" lg="6" xl="4">
          <code-field-text
            v-model="obModel.code"
            label="code"
            item-key="category"
            :show-code-button="!obModel.code"
          />
        </v-col>

        <v-col cols="12" lg="6" xl="4">
          <account-code-select
            payment-method-code="NINGUNO"
            v-model="sCodeAccount"
          />
        </v-col>

        <v-col cols="12" lg="6" xl="4">
          <company-preview />
        </v-col>

        <v-col cols="12">
          <v-textarea
            :label="$t('description')"
            outlined
            v-model="obModel.preview_text"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" lg="4">
      <categories-tree
        v-model="obModel.parent_id"
        label="parent.category"
        open-all
        :required="false"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, VModel, Vue } from "vue-property-decorator";
import { Category } from "@planetadeleste/vue-mc-shopaholic";
import CategoriesTree from "@/components/form/Categories.vue";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";

@Component({
  components: {
    AccountCodeSelect,
    CodeFieldText,
    CategoriesTree,
    CompanyPreview,
  },
})
export default class CategoriesSettings extends Vue {
  @VModel({ type: Object, default: () => new Category() }) obModel!: Category;

  get sCodeAccount(): string {
    return this.obModel.get("code_account", "");
  }

  set sCodeAccount(sValue: string) {
    this.obModel.set("code_account", sValue);
  }
}
</script>
